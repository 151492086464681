import { useState, useEffect, useContext, createContext, useMemo } from "react";
import { firestore } from "../config/firebaseInit";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "./useAuth";

const getData = async (path, pathSegments) => {
    const ref = doc(firestore, path, pathSegments);
    const snap = await getDoc(ref);
    if (!snap.exists) return null;
    return snap.data();
};

const getJurisdictionData = (organization) => {
    if (!organization) return null;

    const orgNameArray = organization.split(" ");
    const jurisdictionName = orgNameArray.slice(0, -2).join(" ");
    const jurisdictionType = orgNameArray[orgNameArray?.length - 2];
    const jurisdictionStateAbbr = orgNameArray[orgNameArray?.length - 1];

    return {
        name: jurisdictionName,
        type: jurisdictionType,
        stateAbbr: jurisdictionStateAbbr,
    };
}

export const JurisdictionContext = createContext(null);

export const useJurisdiction = () => {
    return useContext(JurisdictionContext);
};

export const JurisdictionContextProvider = ({ children }) => {
    const auth = useAuth();
    const [jurisdiction, setJurisdiction] = useState(null);
    const jurisdictionProvider = useMemo(() => ({ jurisdiction, setJurisdiction }), [jurisdiction, setJurisdiction]);

    useEffect(() => {
        if (auth?.user?.uid) {
            (async () => {
                const { user } = auth;
                const userData = await getData("users", user.uid);
                const org = await getData("organizations", userData.currentOrganization);
                const jurisdictionData = getJurisdictionData(org?.name);
                setJurisdiction(jurisdictionData);
            })();
        }
    }, [auth]);
  
    return (
      <JurisdictionContext.Provider value={jurisdictionProvider}>
        { children }
      </JurisdictionContext.Provider>
    );
};
  