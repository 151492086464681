import { Button, Navbar, Nav } from "react-bootstrap";
import logo from "../../images/rc-logo-gov.png";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";

export default function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  async function handleSignOut() {
    try {
      await auth.signout();
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <Navbar
      id="sidebar"
      className="grey-background navbar-dark sticky-sm-top px-2"
    >
      <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-1 pt-2 text-white">
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white" }}
          className="fs-4 display-6 text-uppercase ls-1"
        >
          <img
            alt="reason-logo"
            id="reason-logo"
            className="mb-3 me-2"
            src={logo}
            width="120"
            height="auto"
          />{" "}
          <span className="d-sm-block">Individual</span>
          <span className="d-sm-block">Personal</span>
          <span className="d-sm-block">Property</span>
        </Link>
        <hr className="w-100 d-none d-lg-block" />
        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="float-end my-2"
        />
        <Navbar.Collapse id="navbarScroll" className="flex-md-column">
          <Nav className="flex-md-column my-md-2">
            <span className="fw-bold mt-4 mt-md-0 px-2">
              Applications
            </span>
            <Nav.Item>
              <div className={`nav-section mt-2 mt-md-4`}>
                <Nav.Link
                  to={config.ssoUrl}
                  href={config.ssoUrl}
                  className={`nav-link text-white rounded-3 px-2 fw-semibold`}
                >
                  SSO
                </Nav.Link>
              </div>
            </Nav.Item>
          </Nav>
          <div className="py-sm-2 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
            <Button
              id="log-out-btn"
              variant="outline-light"
              size="lg"
              className="text-nowrap text-uppercase ls-1"
              onClick={handleSignOut}
            >
              Log Out
            </Button>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
