import { ProvideAuth } from "./hooks/useAuth";
import { JurisdictionContextProvider } from "./hooks/useJurisdiction";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/auth/Login";
import ReviewForm from "./components/ReviewForm";

//QA Environment Banner
import QABanner from "./components/shared/QABanner";
import config from "./config/hostnames";

export default function App() {
  //Layout bottom margin for QA Fixed Top Banner
  const layoutMargin = config.devMode ? "mt-5" : "";
  return (
    <div className={`${layoutMargin}`}>
      {config.devMode && <QABanner />}
      <ProvideAuth>
        <JurisdictionContextProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/ipp/:status/:gcpStorageKey"
                element={<ReviewForm />}
              />
            </Routes>
          </Router>
        </JurisdictionContextProvider>
      </ProvideAuth>
    </div>
  );
}
