export const configs = {
    "localhost": {
        releaseStage: "development",
        ssoUrl: "http://gov-sso-web.apps.localhost:6002/",
    },
    "qa-ipp.gov.taxscribe.app": {
        releaseStage: "qa",
        ssoUrl: "https://qa-sso.gov.taxscribe.app/",
    },
    "ipp.gov.taxscribe.app": {
        releaseStage: "production",
        ssoUrl: "https://sso.gov.taxscribe.app/",
    },
};

const hostname = window.location.hostname;
const getConfig = () => {
    if (hostname.match("localhost")) return configs.localhost;
    const config = configs[hostname];
  
    if (!config) throw new Error(`No configuration found for ${hostname}`);
    return config;
};
  
export default getConfig();
