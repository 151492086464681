const mappingByState = {
    "MI": { 
        LISTING: "Statement",
        LISTINGS: "Statements" 
    },
};
  
const UseProcessFormName = ({
    stateAbbr,
    shortName,
}) => {
    const stateMappings = mappingByState[stateAbbr.toUpperCase()];
    if (!stateMappings) return shortName;
  
    const name = stateMappings[shortName.toUpperCase()];
    return name || shortName;
};

export default UseProcessFormName;